import * as React from "react";
import {useState, useMemo, useEffect} from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { createOwnerChange, getOwnerByData } from "../services/ownerChange";
import { getFoalById, getFoalByInfo } from "../services/foals";

import {
  Input,
  InputNumber,
  SubmitButton,
  Select,
  Form,
  Checkbox,
} from "formik-antd";
import { Field, Formik } from "formik";
import {
  Typography,
  Breadcrumb,
  Switch,
  Row,
  Divider,
  Statistic,
  List,
  Space,
  Col,
  Anchor,
  Tag,
  Button,
  message,
  notification,
  Progress,
  Upload,
  Alert,
  TreeSelect as $TreeSelect,
  Modal,
  Descriptions, Segmented, Card,
} from "antd";
import { useRealmApp } from "../utils/RealmApp";
import { getOwner } from "../services/owners";
import { getDam } from "../services/dams";
import {getAllSortedSires, getSire} from "../services/sires";
import OwnerFoalNotFoundModal from "../components/OwnerChangeFoalNotFoundModal";
import OwnerFoalFoundModal from "../components/OwnerChangeFoalFoundModal";
import {ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons";

import { FoalSearch } from "../components/FoalSearch";
import { EventSelect } from "../components/EventSelect";
import { RiderSelect } from "../components/RiderSelect";
import { create } from "lodash";
import axios from "axios";
import useResponsive from "../hooks/useResponsive";
import InfoUpdateRequestModal from "../components/InfoUpdateRequestModal";
import { getDamSire } from "../services/damSires";

const { Title, Text, Paragraph } = Typography;

export const OwnerChangeForm = () => {
  const app = useRealmApp();
  //useState values here
  const [foal, setFoal] = useState({});
  const [result, setResult] = useState({});
  const [owner, setOwner] = useState({});
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [sire, setSire] = useState({});
  const [sires, setSires] = useState([]);
  const [dam, setDam] = useState({});
  const [api, contextHolder] = notification.useNotification();
  const [fileUpload, setFileUpload] = useState({});
  const [progress, setProgress] = useState(0);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [isResultHorse, setIsResultHorse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFoalModalOpen, setIsFoalModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [regNum, setRegNum] = useState(searchParams.get("regnum"));
  const [foalId, setFoalId] = useState(searchParams.get("foalId"));
  const [attempts, setAttempts] = useState(0);
  const [isUpdateInfoModalOpen, setIsUpdateInfoModalOpen] = useState(false);
  const [damSire, setDamSire] = useState({});
  const [searchFormOption, setSearchFormOption] = useState('Registration Number');

  const { isXs, isSm } = useResponsive();

  const isMobile = isXs || isSm;

  useEffect(() => {
    getAllSires();
  }, []);

  const getAllSires = async () => {
    let sires = await getAllSortedSires(app);
    setSires(sires);
  };

  // Foal Not Found Modal
  const openModal = () => {
    setIsConfirmed(false);
    setIsModalOpen(true);
    console.log(isConfirmed);
  };

  const handleOnChange = ({ file, fileList, event }) => {
    setDefaultFileList(fileList);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Foal  Found Modal
  const openFoalModal = () => {
    setIsFoalModalOpen(true);
  };

  const closeFoalModal = () => {
    setIsFoalModalOpen(false);
    setResult(null);
  };

  const openNotificationWithIcon = (type, title, description, placement) => {
    api[type]({
      message: title,
      description: description,
      placement,
      duration: 0,
    });
  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    setFileUpload({});
    try {
      const { onSuccess, onError, file, onProgress } = options;

      const fmData = new FormData();
      const config = {
        headers: { "Content-Type": file.type },

        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append("image", file);
      const getUrl = await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/customers/upload`,
        {
          input: { prefix: "papers", name: file.uid, filetype: file.type },
        }
      );

      const res = await fetch(getUrl.data.url, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": file.type },
      });
      setFileUpload(file);
      onSuccess("Ok");
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const getPendingFoalInfo = async () => {
    let result = await getFoalById(app, foalId)
    setFoal(result)
    setResult(result)
    setIsFoalModalOpen(true)
    const fetchedOwner = await getOwner(app, result.owner?.toString());
    setOwner(fetchedOwner);
    const fetchedDam = await getDam(app, result.dam?.toString());
    setDam(fetchedDam);
    const fetchedSire = await getSire(app, result.sire?.toString());
    setSire(fetchedSire);
    const fetchedDamSire = await getDamSire(app, result.damSire?.toString());
    setDamSire(fetchedDamSire);  
  }

  const handleSearchResult = async (data) => {

    setAttempts(1);
    if (regNum !== "PENDING" && regNum?.toString() !== 'null') {
    setResult(data);
    setIsModalOpen(data === "none");
    if (typeof data === "object") {
      setIsResultHorse(true);
      setFoal(data);
      const fetchedOwner = await getOwner(app, data.owner?.toString());
      setOwner(fetchedOwner);
      const fetchedDam = await getDam(app, data.dam?.toString());
      setDam(fetchedDam);
      const fetchedSire = await getSire(app, data.sire?.toString());
      setSire(fetchedSire);
      const fetchedDamSire = await getDamSire(app, data.damSire?.toString());
      setDamSire(fetchedDamSire);
    } else {
      setIsResultHorse(false);
    }
    } else {
      await getPendingFoalInfo()

    }   
  };

  const handleConfirm = () => {
    setIsConfirmed(false);
    closeFoalModal();
  };

  const handleOwnerChange = () => {
    setIsConfirmed(true);
    closeFoalModal();
  };

  const redirectToFoalUpdate = () =>{
    window.location.href = `/foal-update?regnum=${result?.regNum}&foalId=${result?._id}`;
  }
  return (
    <>
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      ></Breadcrumb>
      {contextHolder}
      <Formik
        //enableReinitialize={true}
        initialValues={{
          foal: foal,
          owner: owner,
          registration_number: regNum ? regNum : "",
          email: "",
          address: "",
          address3: "",
          name: "",
          phone: "",
          papers: {},
        }}
        onSubmit={async (values, actions) => {
          const payload = {
            foal: foal._id,
            name: values.name.toUpperCase() || "",
            owner: owner._id,
            email: values.email.toUpperCase() || "",
            address: values.address.toUpperCase() || "",
            phone: values.phone,
            address3: ((values.city.toUpperCase()) + ", " + (values.state.toUpperCase()) + " " + (values.zipcode) )|| "",
            createdAt: new Date(),
            papers: fileUpload,
          };
            if (!isConfirmed) {
              const data = await getFoalByInfo(app, values);
              if (data) {
                handleSearchResult(data);
              } else {
                handleSearchResult("none");
              }
            } else {
              let checkOwner = await getOwnerByData(app, {name: payload.name.toUpperCase(), email: payload.email.toUpperCase()});
            
              if(checkOwner) {
                payload.ownerSubmit = checkOwner._id;
                payload.newOwnerSubmit = false;
              }
              else {
                payload.newOwnerSubmit = true;
              }

              await createOwnerChange(app, payload);
              // actions.resetForm();
              setIsConfirmed(false);
              openNotificationWithIcon(
                "success",
                "Owner Change",
                `We have received your request for an owner change on ${foal.name}. We will notify when the change has been approved.`,
                "bottomRight"
              );
            }
        }}
        validate={(values) => {
          const errors = {};

          if (!values.registration_number) {
            if (!values.yob) {
              errors.yob = "required";
            }
            if (!values.sire) {
              errors.sire = "required";
            }
            if (!values.dam) {
              errors.dam = "required";
            }
            if (Object.entries(errors).length === 0 && !isConfirmed) {
              return;
            }
          }
          if (!Object.keys(fileUpload).length) {
            errors.papers = "required";
          }
          if (!values.name) {
            errors.name = "required";
          }
          if (!values.email) {
            errors.email = "required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.address) {
            errors.address = "required";
          }

          if (!values.city) {
            errors.city = "required";
          }
          if (!values.state) {
            errors.state = "required";
          }
          if (!values.zipcode) {
            errors.zipcode = "required";
          }

          if (!values.phone) {
            errors.phone = "required";
          }
          return errors;
        }}
        render={(formik) => (
          <div className="container">

            <Title level={4}>
              Please use this form to change the Owner of a horse.
            </Title>
            <Card>
              <Segmented
                defaultValue='Registration Number'
                style={{
                  marginBottom: 20,
                }}
                onChange={(value) => setSearchFormOption(value)}
                options={['Registration Number', 'Alternate Information']}
              />
            <Form
              layout="vertical"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 14 }}
            >
              {searchFormOption === "Registration Number"  && <>
                <Title level={5}>
                  Search if horse is enrolled by breed registration
                  number:
                </Title>
                <Space direction="vertical" style={{width: "100%"}}></Space>
                <br></br>
                <Form.Item
                  label="Reg #"
                  name="registration_number"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <FoalSearch
                    name="registration_number"
                    onSearchResult={handleSearchResult}
                    prevalue={regNum}
                    attempts={attempts}
                  />
                </Form.Item>
              </>}
              {searchFormOption === "Alternate Information" && <>
              <Space>
                <Title level={5}>Search by alternate information :</Title>
              </Space>
              <Tag color="red">* all fields required</Tag>
              <Form.Item
                hasFeedback={true}
                rules={[{ required: true }]}
                showValidateSuccess={true}
                label="Year of Birth"
                name="yob"
              >
                <Input name="yob" />
              </Form.Item>
              <Form.Item
                label="Sire Name"
                name="sire"
                hasFeedback={true}
                rules={[{ required: true }]}
                showValidateSuccess={true}
              >
                <Select
                  name="sire"
                  placeholder="Sire Name"
                  showSearch
                  value={sire}
                  onChange={value => setSire(value)}
                  filterOption={(input, option) => {
                    return (
                      option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) >= 0
                    );
                  }}
                >
                  {sires.map((sire) => (
                    <Select.Option
                      key={sire._id.toString()}
                      id={sire._id.toString()}
                      value={sire.id}
                    >
                      {sire.name} - {sire?.yearEnrolled || ""}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                hasFeedback={true}
                rules={[{ required: true }]}
                showValidateSuccess={true}
                label="Dam Name"
                name="dam"
              >
                <Input name="dam" />
              </Form.Item>
              <Form.Item label="" name="submit">
                <Button.Group size="medium">
                  <SubmitButton
                    style={{
                      backgroundColor: "#a36b53",
                      borderColor: "#a36b53",
                      marginTop: isMobile ? "auto" : "1rem",
                      marginLeft:  isMobile ? "auto" : "6rem",
                    }}
                    disabled={false}
                  >
                    Search
                  </SubmitButton>
                </Button.Group>
              </Form.Item>
              </>}
              {result
                ? isResultHorse && openFoalModal()
                : result === "none" && openModal()}
              <OwnerFoalNotFoundModal
                isModalOpen={isModalOpen}
                closeModal={closeModal}
              />
              <OwnerFoalFoundModal
                isFoalModalOpen={isFoalModalOpen}
                closeFoalModal={closeFoalModal}
                openFoalModal={openFoalModal}
                result={result}
                owner={owner}
                dam={dam}
                sire={sire}
                handleConfirm={handleConfirm}
                handleOwnerChange={handleOwnerChange}
                handleUpdateFoalInfo={redirectToFoalUpdate}
                //autoClick={regNum ? true : false}
              />

              {isConfirmed ? (
                <Row  style={{ marginBottom: "2rem" }}>
                  <Col
                      style={{
                        ...(isMobile
                                ? { borderTop: "1px solid #ccc", padding: "1rem 0" }
                                : { borderLeft: "1px solid #ccc", padding: "1rem" }
                        ),
                      }}
                      xs={24} sm={24} md={12} lg={12}
                  >
                    <Descriptions
                      size="small"
                      column={1}
                      title="Horse Information"
                      style={{}}
                    >
                      <Descriptions.Item label={<b>Name</b>}>
                        {foal?.name}
                      </Descriptions.Item>

                      <Descriptions.Item label={<b>Sire</b>}>
                        {sire?.name}
                      </Descriptions.Item>
                      <Descriptions.Item label={<b>Dam</b>}>
                        {dam?.name}
                      </Descriptions.Item>
                      <Descriptions.Item label={<b>Year of Birth</b>}>
                        {foal?.yob}
                      </Descriptions.Item>
                      <Descriptions.Item label={<b>Sex</b>}>
                        {foal?.sex?.toUpperCase()}
                      </Descriptions.Item>
                    </Descriptions>
                    <Button
                      href={`/foal-update?regNum=${regNum}`}
                      // target="_blank"
                      style={{
                        // backgroundColor: "#a36b53",
                        // borderColor: "#a36b53",
                        // color: "white",
                        marginTop: "0.5rem",
                      }}
                    >
                      Update Horse Info
                    </Button>
                  </Col>

                  <Col
                      style={{
                        ...(isMobile
                                ? { borderTop: "1px solid #ccc", paddingTop: "1rem" }
                                : { borderLeft: "1px solid #ccc", padding: "1rem" }
                        ),
                      }}
                      xs={24} sm={24} md={12} lg={12}
                  >
                    <Descriptions
                      size="small"
                      column={1}
                      title="Current Owner Information"
                    >
                      <Descriptions.Item label={<b>Name</b>}>
                        {owner.name}
                      </Descriptions.Item>
                      <Descriptions.Item label={<b>Address</b>}>
                        {owner.address1}
                      </Descriptions.Item>
                      <Descriptions.Item label={<b>City, State, Zipcode</b>}>
                        {owner.address3}
                      </Descriptions.Item>
                      <Descriptions.Item label={<b>Phone</b>}>
                        {owner.phone}
                      </Descriptions.Item>
                      <Descriptions.Item label={<b>Email</b>}>
                        {owner.email}
                      </Descriptions.Item>
                    </Descriptions>
                    <Button
                      // href="https://www.tomorrowslegendsllc.com/infoupdate"
                      // target="_blank"
                      onClick={()=>setIsUpdateInfoModalOpen(true)}
                      style={{
                        // backgroundColor: "#a36b53",
                        // borderColor: "#a36b53",
                        // color: "white",
                        marginTop: "0.5rem",
                      }}
                    >
                      Update Current Owner Info
                    </Button>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {isConfirmed && (
                <>
                  <Form.Item
                    label="Name"
                    name="name"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Tag style={{ marginBottom: "4px" }} color="red">
                      *Must match Registration papers exactly*
                    </Tag>
                    <Input name="name" placeholder="Name" />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="email"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="email" placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Phone"
                    name="phone"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="phone" placeholder="Phone" />
                  </Form.Item>

                  <Form.Item
                    label="Address"
                    name="address"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="address" placeholder="Address" />
                  </Form.Item>

                  <Form.Item
                    label="City"
                    name="city"
                    hasFeedback={true}
                    showValidateSuccess={true}
                    >
                    <Input name="city" placeholder="City" />
                    </Form.Item>
                    <Form.Item
                    label="State"
                    name="state"
                    hasFeedback={true}
                    showValidateSuccess={true}
                    >
                    <Input name="state" placeholder="State" />
                    </Form.Item>
                    <Form.Item
                    label="Zipcode"
                    name="zipcode"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="zipcode" placeholder="Zipcode"/>
                  </Form.Item>
                  <Form.Item label="Papers" name="papers">
                    <Upload
                      accept="*"
                      name="papers"
                      customRequest={uploadImage}
                      onChange={handleOnChange}
                      defaultFileList={defaultFileList}
                      className="image-upload-grid"
                    >
                      {defaultFileList.length >= 1 ? null : (
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      )}
                    </Upload>
                    {defaultFileList.length === 0 &&
                        <Tag style={{ whiteSpace: "normal", marginTop: "4px" }} color="warning" icon={<ExclamationCircleOutlined/>}>
                          We must receive papers in your name to approve the owner change. If papers are at AQHA in transfer, please upload a copy of the signed completed transfer.
                        </Tag>
                    }
                  </Form.Item>
                  <Form.Item label="" name="submit">
                    <Button.Group size="large">
                      <SubmitButton
                        style={{
                          // backgroundColor: "#a36b53",
                          // borderColor: "#a36b53",
                          marginTop: isMobile ? "auto" : "1rem",
                          marginLeft:  isMobile ? "auto" : "6rem",
                        }}
                        disabled={false}
                      >
                        Submit Owner Change
                      </SubmitButton>
                    </Button.Group>
                  </Form.Item>
                </>
              )}
            </Form>
              <InfoUpdateRequestModal
                entity={"Owner"}
                info={owner}
                isModalOpen={isUpdateInfoModalOpen}
                closeModal={()=>setIsUpdateInfoModalOpen(false)}
              />
            </Card>
          </div>
        )}
      />
    </>
  );
};
